<template>
  <div class="owner-dashboard" >
    <div>
      <b-row no-gutters>
        <b-col cols="2" lg="2" sm="0"></b-col>
        <b-col cols="8" lg="8" sm="12">
          <div class="p-1">
            <b-card @click="$router.push('/monetary')">
              <div class="text-center">{{ $t('Dashboard')}} <i class="fas fa-chart-area"></i></div>
            </b-card>
          </div>
        </b-col>
        <b-col cols="2" lg="2" sm="0"></b-col>

        <b-col cols="12" lg="6">
          <div class="p-1">
            <b-card @click="$router.push('/realestates')">
              <div class="text-center">{{$t('myRealEstates')}} <i class="fas fa-home"></i><i class="fas fa-building"></i></div>
            </b-card>

          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div class="p-1">
            <b-card @click="$router.push('/contract')">
              <div class="text-center">{{ $t('myContracts')}} <i class="fas fa-file-signature"></i></div>
            </b-card>
          </div>
        </b-col>

<!--        <b-col cols="12" lg="6">
          <div class="p-3">
            <b-card>
              <div class="text-center"> Profile <i class="fas fa-address-card"></i></div>
            </b-card>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div class="p-3">
            <b-card>
              <div class="text-center"> ?My Expose? <i class="fas fa-home"></i><i class="fas fa-building"></i></div>
            </b-card>
          </div>
        </b-col>-->
      </b-row>

    </div>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  mounted() {
    // this.$router.push("/contract")
  },
}
</script>

<style lang="scss">
@import "src/assets/scss/base/bootstrap-extended/_variables.scss";
.owner-dashboard {
  background: url('https://treasurehome.at/_nuxt/img/home.9a1e90d.jpg');
  background-size: cover;
  background-position: 50%;
  margin-top: -25px;
  height: calc(100vh - 52px);

  > div {
    height: 100%;
    padding-top: 30px;

    .card {
      background-color: rgba(194, 170, 142, 0.83);
      color: rgba(255, 255, 255, 0.7);
      height: 100px;
      font-size: 30px;
      padding-top: 10px;
      cursor: pointer;

      &:hover:not(.disabled):not(:disabled) {
        box-shadow: 0 8px 25px -8px #8aa099;
        color: $secondary;
      }
    }

  }

}
</style>
